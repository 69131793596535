<template>
  <modal
    :name="modalName"
    :width="600"
    :adaptive="true"
    :clickToClose="false"
    height="90%"
    class="modal--service-fee"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('confirmServiceFeeForm.title') }}
      </div>
      <section class="modal--container">
        <SectionLoader :show="loading" />
        <div class="title--text" v-if="$mq !== 'xs'">{{ $t('confirmServiceFeeForm.title') }}</div>
        <div role="alert" class="alert alert-warning mt-2">
          {{ $t('confirmServiceFeeForm.warning') }}
        </div>
        <div class="service--fee-content">
          {{ $t('confirmServiceFeeForm.content') }}
          <br />
          <br />
          {{ $t('confirmServiceFeeForm.content2') }}
        </div>
        <hr />
        <div class="custom-control custom-checkbox">
          <input v-model="isAgree" type="checkbox" class="custom-control-input" id="agreementTC" />
          <label class="custom-control-label" for="agreementTC">
            <span>{{ $t('confirmServiceFeeForm.agreeTC1') }}</span>
            <a href="/term-of-service" target="_blank">{{
              $t('confirmServiceFeeForm.agreeTC2')
            }}</a>
            <span>.</span>
          </label>
        </div>
        <button :disabled="!isAgree" @click="postDone" class="btn btn-primary main--btn">
          {{ $t('confirmServiceFeeForm.btn') }}
          <span class="ld ld-ring ld-spin"></span>
        </button>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'modal-confirm-service-fee-tc',
  components: { SectionLoader },
  props: {},
  data() {
    return {
      modalName: 'modal-confirm-service-fee-tc',
      isAgree: false,
      loading: false,
      postDoneAction: null,
    };
  },
  methods: {
    async beforeOpen(event) {
      if (event.params && event.params.postDoneAction !== null) {
        this.postDoneAction = event.params.postDoneAction;
      }
    },
    async postDone() {
      try {
        this.loading = true;
        await this.$store.dispatch('v2/profile/confirmServiceFeeTc');
        this.$emit('postDone');
        if (this.postDoneAction !== null) {
          this.postDoneAction();
        }
        this.$modal.hide(this.modalName);
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit('notNow');
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style></style>
